@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600;700;800&display=swap');

:root {
  --font-family-sans: 'Quicksand', sans-serif;
}

.sans-font {
    font-family: var(--font-family-sans);
}


// Autosuggest

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  bottom: 56px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 99999;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}



@media (max-width: 900px) and (max-height: 900px) {
  // Custom Rounding
  .custom-rounded-none {
    border-radius: 0;
  }
  // Custom background
  .custom-background-none {
    background: none;
  }
}

// Custom Too Narrow Viewport Height
@media (max-height: 500px) {
  // Remove for now
  //.h-svh {
    //height: 100%;
  //}
}
